<script setup lang="ts">
import { specializationList } from "@/state/state";
const isMenuOpen = ref(false);
</script>

<template>
    <VRow no-gutters align="center" justify="center" class="fill-height navigation-wrapper text-uppercase">
        <VCol cols="auto" class="fill-height d-flex align-center navigation-link-wrapper">
            <NuxtLink class="fill-height navigation-link" exact-active-class="active" to="/"> Home </NuxtLink>
        </VCol>
        <VCol cols="auto" class="fill-height d-flex align-center navigation-link-wrapper">
            <VMenu v-model="isMenuOpen" location="bottom" :close-on-content-click="false" open-on-focus open-on-hover>
                <template #activator="{ props }">
                    <NuxtLink tabindex="0" role="button" v-bind="props" class="fill-height navigation-link position-relative" exact-active-class="active">
                        Services
                    </NuxtLink>
                </template>
                <VList role="list" color="primary" density="comfortable">
                    <VListGroup v-for="specialization in specializationList.getElements()" :key="specialization.id" :value="specialization.name">
                        <template #activator="{ props }">
                            <VListItem v-bind="props" :title="specialization.name"></VListItem>
                        </template>
                        <VListItem title="Explore" :to="`/specialization/${stringToHash(specialization.name)}/${specialization.id}`"></VListItem>
                        <VListItem
                            v-for="service in specialization.servicesNamesAndPrices"
                            :key="service.id"
                            :title="service.name"
                            :to="`/service/${stringToHash(specialization.name)}/${stringToHash(service.name)}/${service.id}`"
                        ></VListItem>
                    </VListGroup>
                </VList>
            </VMenu>
        </VCol>
        <VCol cols="auto" class="fill-height d-flex align-center navigation-link-wrapper">
            <NuxtLink class="fill-height navigation-link" exact-active-class="active" to="/staff"> Team </NuxtLink>
        </VCol>
        <VCol cols="auto" class="fill-height d-flex align-center navigation-link-wrapper">
            <NuxtLink class="fill-height navigation-link" exact-active-class="active" to="/cost"> Cost </NuxtLink>
        </VCol>
        <VCol cols="auto" class="fill-height d-flex align-center navigation-link-wrapper">
            <NuxtLink class="fill-height navigation-link" exact-active-class="active" to="/faq"> FAQ </NuxtLink>
        </VCol>
        <VCol cols="auto" class="fill-height d-flex align-center navigation-link-wrapper">
            <NuxtLink class="fill-height navigation-link" exact-active-class="active" to="/gallery"> Gallery </NuxtLink>
        </VCol>
        <VCol cols="auto" class="fill-height d-flex align-center navigation-link-wrapper">
            <NuxtLink class="fill-height navigation-link" exact-active-class="active" to="/contact"> Contact us </NuxtLink>
        </VCol>
    </VRow>
</template>
<style lang="scss" scoped>
.navigation-wrapper {
    gap: 1.75rem;

    .navigation-link-wrapper {
        .navigation-link {
            text-decoration: none;
            cursor: pointer;
            text-align: center;
            font-size: 0.875rem;
            display: flex;
            align-items: center;

            &.active {
                font-weight: 600;
                color: rgb(var(--v-theme-primary));
            }

            &:hover,
            &:focus-visible,
            &:focus-within {
                color: rgb(var(--v-theme-text-red));
            }
        }
    }
}
</style>
